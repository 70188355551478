import React from 'react';
import Link from '/src/components/LocalizedLink';
import Layout from '../../components/Layout';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';
import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';

import iconTick from '../../assets/images/svg/tick.svg';

import '../../assets/styles/pages/all-courses-page.scss';

const AllCourses = () => {
  return (
    <Layout
      title={{ id: 'allCourses.seo.title' }}
      description={{ id: 'allCourses.seo.description' }}
    >
      <div className="all-courses-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">All courses</h1>
          </div>
        </section>

        <section className="second-section c-section-py">
          <div className="container">
            <h2 className="second-section__title c-title-34">TEFL Course Information</h2>
            <p className="second-section__description c-text-18">
              Looking for a fun, flexible, and rewarding career path that is in-demand across the
              globe? If you’re passionate about travel, interested in new cultures, and motivated to
              help others, a career as an international English teacher is right up your alley!
            </p>
            <div className="row">
              {/* <div className="col-lg-4 col-md-6 col-12">
                <div className="item ">
                  <h3 className="item__title">Part-Time Online Trinity CertTESOL Course</h3>
                  <div className="benefits-wrapper">
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Course:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">12 weeks of intensive training online.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Internationally recognised certificate.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">10 hours of bonus pre-course study material.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Course books and resources sent to your home.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">1-on-1 careers & job finding session.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Written appraisal.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          6+ hours of teaching practice with real students.
                        </p>
                      </div>
                    </div>
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Also included:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Lifetime access to job mailing list. </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Access to school social calendar.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Free access to in-person workshops for 1 year.</p>
                      </div>
                    </div>
                  </div>
                  <div className="item__btn">
                    <h3 className="price">1550€</h3>
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="item ">
                  <h3 className="item__title">Trinity CertTESOL Course</h3>
                  <div className="benefits-wrapper">
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Course:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">4 weeks of intensive training in Barcelona.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Internationally recognised certificate.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">10 hours of bonus pre-course study material.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Course books and resources.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">1-on-1 careers & job finding session.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Written appraisal.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          6+ hours of teaching practice with real students.
                        </p>
                      </div>
                    </div>
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Also included:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Pre-course breakfast.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Social calendar at the school.</p>
                      </div>
                    </div>
                  </div>
                  <div className="item__btn">
                    <h3 className="price">
                      1650€ / 1550€ <span className="period">Early Bird</span>
                    </h3>
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="item ">
                  <h3 className="item__title">Developing Teacher Course</h3>
                  <div className="benefits-wrapper">
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Course:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          20 hours per week of course work (3 hours in-school sessions per week).
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Expert tuition from professional teachers.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Access to our beautiful school library, high-speed wifi, computers.
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Job contacts and placement help.</p>
                      </div>
                    </div>
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Also included:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          All the paperwork and guidance necessary to process your student visa.
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Access to our network of Barcelona teachers.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Special discount on health insurance for U.S. applicants.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item__btn">
                    <h3 className="price">
                      <span>
                        1500€ <span className="period">/year</span>
                      </span>
                      <br />
                      <br />
                      <span>
                        150€ <span className="period">/month</span>
                      </span>
                    </h3>
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="item ">
                  <h3 className="item__title">Spanish Course</h3>
                  <div className="benefits-wrapper">
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Option 1:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Spanish taster course.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">6 hours of beginner Spanish lessons.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Lessons take place during your TEFL course.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          {' '}
                          <strong>Price: 60€</strong>
                        </p>
                      </div>
                    </div>
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Option 2:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">6 hours per week of Spanish lessons.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">All levels, beginner to advanced.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Regular outings in Barcelona (markets, museums, cafes, festivals).
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Valid for Spanish student visa.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          All the paperwork and guidance necessary to process your student visa.
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Special discount on health insurance for U.S. applicants.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item__btn">
                    <h3 className="price">
                      200€ <span className="period">/month</span>
                    </h3>
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="item ">
                  <h3 className="item__title">Teaching Young Learners</h3>
                  <div className="benefits-wrapper">
                    <div className="c-benefit-list">
                      <h4 className="item__list-title">Course:</h4>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          3 days of practical learning - workshops, demos, classes.{' '}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          6 hours of observation and teaching practice with real learners.
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Observation of experienced teachers.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">Guided lessons planning.</p>
                      </div>
                      <div className="benefit">
                        <img src={iconTick} alt="iconTick" />
                        <p className="c-text-16">
                          Experience with a range of ages: 5-16 year old students.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="item__btn">
                    <h3 className="price">300€</h3>
                    <Link to="/apply/" className="c-btn c-btn--red">
                      Sign Up Now
                      <IconArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="third-section c-section-py">
          <div className="container">
            <h2 className="third-section__title c-title-34">Timetables</h2>
            <div className="item">
              {/* <div className="line">
                <h4 className="course">100% Online Trinity CertTESOL Course:</h4>
                <p className="duration">18.00 - 21.00</p>
                <p className="period">Monday - Friday</p>
              </div> */}
              <div className="line">
                <h4 className="course">Trinity CertTESOL Course:</h4>
                <p className="duration">09.30-18.00</p>
                <p className="period">Monday - Friday</p>
              </div>
              <div className="line">
                <h4 className="course">Developing Teacher Course:</h4>
                <p className="duration">09:00-10:30</p>
                <p className="period">Tuesdays and Thursdays</p>
              </div>
              <div className="line">
                <h4 className="course">Young Learner Course:</h4>
                <p className="duration">10:30-19:00</p>
                <p className="period">Monday, Tuesday, Wednesday</p>
              </div>
              <div className="line">
                <h4 className="course">Spanish Lessons:</h4>
                <p className="duration">09:30-11:30</p>
                <p className="period">Monday - Friday</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default AllCourses;
